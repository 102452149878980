import { ComponentStyleConfig } from '@chakra-ui/react'
import { StyleFunctionProps } from '@chakra-ui/theme-tools'

type TagStyleFunctionProps = StyleFunctionProps & {
    'data-disabled'?: boolean
    'data-selected'?: boolean
}

export const Tag: ComponentStyleConfig = {
    parts: ['container', 'label', 'closeButton'],
    baseStyle: {
        container: {
            transition: 'background 0.3s, color 0.3s, border 0.3s',
            _focus: '',
        },
        // NB: A `chakra-react-select` v1.x.x issue workaround.
        closeButton: { _focus: '' },
    },
    sizes: {
        sm: {
            container: {
                padding: '5px 7px',
                borderRadius: '4px',
            },
            label: {
                fontSize: '13px',
                lineHeight: '18px',
            },
        },
        md: {
            container: {
                padding: '7px 12px',
                borderRadius: '4px',
            },
            label: {
                fontSize: '13px',
                lineHeight: '18px',
            },
        },
        lg: {
            container: {
                padding: '9px 19px',
                borderRadius: '12px',
            },
            label: {
                fontSize: '16px',
                lineHeight: '20px',
            },
        },
    },
    variants: {
        applicationChat: (props: TagStyleFunctionProps) => {
            const isSelected = props['data-selected']
            const isDisabled = props['data-disabled']
            const { colorScheme } = props
            const bgColor = `${colorScheme}.500`
            const bgColorHover = `${colorScheme}.600`

            return {
                container: {
                    backgroundColor: isSelected ? bgColor : 'white',
                    color: isSelected ? 'white' : 'text.500',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: 'gray.dividers',
                    padding: '7px 12px',
                    borderRadius: '12px',
                    _focus: {
                        boxShadow: `outline_${colorScheme}_lg`,
                        borderColor: isSelected ? bgColorHover : bgColor,
                        outline: 'none',
                    },
                    _focusVisible: {
                        outline: 'none',
                    },
                    _hover: {
                        backgroundColor: isSelected ? bgColorHover : bgColor,
                        color: 'white',
                        borderColor: isSelected ? bgColorHover : bgColor,
                    },
                    cursor: !isDisabled ? 'pointer' : undefined,
                    pointerEvents: isDisabled ? 'none' : undefined,
                },
                label: {
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '18px',
                },
            }
        },
        primary: (props: TagStyleFunctionProps) => {
            const isSelected = props['data-selected']
            const isDisabled = props['data-disabled']
            const { colorScheme } = props
            const bgColor = `${colorScheme}.500`
            const bgColorHover = `${colorScheme}.600`

            return {
                container: {
                    backgroundColor: isSelected ? bgColor : 'white',
                    color: isSelected ? 'white' : 'text.500',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: 'gray.dividers',
                    _focus: {
                        boxShadow: `outline_${colorScheme}_lg`,
                        borderColor: isSelected ? bgColorHover : bgColor,
                        outline: 'none',
                    },
                    _focusVisible: {
                        outline: 'none',
                    },
                    _hover: {
                        backgroundColor: isSelected ? bgColorHover : bgColor,
                        color: 'white',
                        borderColor: isSelected ? bgColorHover : bgColor,
                    },
                    cursor: !isDisabled ? 'pointer' : undefined,
                    pointerEvents: isDisabled ? 'none' : undefined,
                },
                label: {
                    fontWeight: 600,
                },
            }
        },
        secondary: (props: TagStyleFunctionProps) => {
            const isSelected = props['data-selected']
            const isDisabled = props['data-disabled']
            const { colorScheme } = props
            const bgColor = `${colorScheme}.500`
            const bgColorHover = `${colorScheme}.600`

            return {
                container: {
                    backgroundColor: isSelected ? bgColor : 'gray.bg_light',
                    color: isSelected ? 'white' : 'text.500',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: 'transparent',
                    _focus: {
                        boxShadow: `outline_${colorScheme}_lg`,
                        outline: 'none',
                    },
                    _focusVisible: {
                        outline: 'none',
                    },
                    _hover: {
                        backgroundColor: isSelected ? bgColorHover : 'gray.dividers',
                        color: isSelected ? 'white' : 'black',
                    },
                    cursor: !isDisabled ? 'pointer' : undefined,
                    pointerEvents: isDisabled ? 'none' : undefined,
                },
            }
        },
        tertiary: (props: TagStyleFunctionProps) => {
            const isSelected = props['data-selected']
            const isDisabled = props['data-disabled']
            // TODO [FEATURE-277]: This is another case of Chakra's `colorScheme`/theme inflexibility.
            //                     Here we need 2 neighbour tints of 1 tone, e.g. of the 'gray' color.
            const lighterColor = 'gray.background'
            const darkerColor = 'gray.dividers'

            return {
                container: {
                    backgroundColor: isSelected ? lighterColor : 'white',
                    color: isSelected ? 'text.500' : 'text.secondary_light',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: isSelected ? lighterColor : darkerColor,
                    _focus: {
                        boxShadow: `outline_gray_lg`,
                        borderColor: darkerColor,
                        outline: 'none',
                    },
                    _focusVisible: {
                        outline: 'none',
                    },
                    _hover: {
                        backgroundColor: isSelected ? darkerColor : lighterColor,
                        borderColor: darkerColor,
                    },
                    cursor: !isDisabled ? 'pointer' : undefined,
                    pointerEvents: isDisabled ? 'none' : undefined,
                },
            }
        },
    },
    defaultProps: {
        colorScheme: 'secondary',
        variant: 'primary',
        size: 'lg',
    },
}

import { ComponentStyleConfig } from '@chakra-ui/react'

// TODO [FEATURE-661]: Finalize theming for the Form Input components.
export const Textarea: ComponentStyleConfig = {
    baseStyle: ({ _colorScheme }: any) => {
        return {
            field: {
                // doesn't work as it works e.g. for `Input`
                // _placeholder: { color: 'text.secondary' },
            },
        }
    },
    variants: {
        filled: ({ colorScheme, theme }: any) => {
            return {
                borderWidth: '1px',
                _placeholder: {
                    color: 'text.secondary',
                },
                _invalid: {
                    // NB: Forced to use `!important` here to be able to override the `:focused` state.
                    borderColor: '#D21951 !important',
                    boxShadow: `${theme.shadows.error} !important`,
                },
                // NB: The `:focus-visible` should be the only one used, but it doesn't work w/ Chakra.
                _focus: {
                    borderColor: `${colorScheme}.500`,
                    boxShadow: `outline_${colorScheme}`,
                    outline: 'none',
                },
                _focusVisible: {
                    outline: 'none',
                },
                _disabled: {
                    cursor: 'not-allowed',
                },
                _readOnly: {
                    pointerEvents: 'none',
                },
            }
        },
    },
    defaultProps: {
        variant: 'filled',
        colorScheme: 'secondary',
    },
}

// NB: This works as a `Chakra`-wrapped react library component via, the `useStyleConfig` hook.
export const TextareaAutosize: ComponentStyleConfig = {
    baseStyle: ({ colorScheme, theme }: any) => {
        return {
            borderWidth: '1px',
            _placeholder: {
                color: 'text.secondary',
            },
            _invalid: {
                // NB: Forced to use `!important` here to be able to override the `:focused` state.
                borderColor: '#D21951 !important',
                boxShadow: `${theme.shadows.error} !important`,
            },
            // NB: The `:focus-visible` should be the only one used, but it doesn't work w/ Chakra.
            _focus: {
                borderColor: `${colorScheme}.500`,
                boxShadow: `outline_${colorScheme}`,
                outline: 'none',
            },
            _focusVisible: {
                outline: 'none',
            },
            _disabled: {
                cursor: 'not-allowed',
            },
            _readOnly: {
                pointerEvents: 'none',
            },
        }
    },
    defaultProps: {
        colorScheme: 'secondary',
    },
}

import { extendTheme } from '@chakra-ui/react'
// fonts
import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/500.css'
import '@fontsource/montserrat/600.css'
import '@fontsource/montserrat/700.css'
import '@fontsource/montserrat/900.css'
// breakpoints
import { breakpoints } from 'theme/breakpoints'
// colors
import { colors, gradients, shadows } from 'theme/colors'
// styles
import { styles } from 'theme/styles'
// components
import { Avatar } from 'theme/components/Avatar'
import { Button } from 'theme/components/Button'
import { CardWrapper } from 'theme/components/CardWrapper'
import { Checkbox } from 'theme/components/Checkbox'
import { CompanyLogo } from 'theme/components/CompanyLogo'
import { Flair } from 'theme/components/Flair'
import { Heading } from 'theme/components/Heading'
import { Input } from 'theme/components/Input'
import { Link } from 'theme/components/Link'
import { Modal } from 'theme/components/Modal'
import { Notification } from 'theme/components/Notification'
import { Radio } from 'theme/components/Radio'
import { RichTextEditor } from 'theme/components/RichTextEditor'
import { Tag } from 'theme/components/Tag'
import { Text } from 'theme/components/Text'
import { Textarea, TextareaAutosize } from 'theme/components/Textarea'

// IMPLEMENTATION NOTE on how the Chakra UI processes a component's style config.
//
// Let's take, for example, the `Input` component.
//
// 1. Because it's a multipart component, the "default styles" need to be added
//    to the `baseStyle.field` and not just to the `baseStyle`,
// 2. The `baseStyle` styles get overridden by the "modifier styles":
//    - `sizes` styles (borderRadius, fontSize, height, padding)
//    - `variant` styles (background, border, borderColor, _focus, _hover, ...)
// 3. The `variant` styles will override all `sizes` styles,
// 4. Setting the `defaultProps.size` and/or `defaultProps.variant` to `null`
//    will remove _all_ the preset (Chakra's own) "modifier styles".
//
// The simplest way to make a basic change, e.g. to background or border props,
// would be to set it on the variant that you're using — either Chakra's one or
// the project-specific one.
//
// See the following source materials for more details:
// https://chakra-ui.com/docs/styled-system/theming/component-style#styling-multipart-components
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/input.ts#L116
// https://github.com/chakra-ui/chakra-ui/issues/2347#issuecomment-997215492

// TODO [FEATURE-624]: Apply the Styleguide > Visual Foundations > Spacing.
export const chakraTheme = extendTheme({
    fonts: {
        heading: `'Montserrat', 'Open Sans', sans-serif`,
        body: `'Montserrat', 'Open Sans', sans-serif`,
        mono: 'monospace',
    },
    variables: {
        header: {
            base: '60px',
            md: '80px',
        },
        headerConversation: {
            base: '69px',
            md: '80px',
        },
    },
    colors,
    breakpoints,
    shadows,
    gradients,
    styles,
    cardSizes: {
        xs: '240px',
        sm: '320px',
        md: '349px',
        levelCard: '130px',
    },
    components: {
        Avatar,
        Button,
        CardWrapper,
        Checkbox,
        CompanyLogo,
        Heading,
        Input,
        Link,
        Modal,
        Notification,
        RichTextEditor,
        Tag,
        Text,
        Textarea,
        TextareaAutosize,
        Radio,
        Flair,
    },
})

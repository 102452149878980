import * as Sentry from '@sentry/nextjs'
import { getEnvGlobal, getEnvironment } from './config'

const dsn = getEnvGlobal('sentryDSN')
const env = getEnvironment()

if (dsn && env && env !== 'dev') {
  Sentry.init({
    dsn,
    tracesSampleRate: 1.0,
    environment: env,
  })
}

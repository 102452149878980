const modalPaddingX = { base: '16px', md: '48px' }

export const Modal = {
    parts: ['overlay', 'dialog', 'header', 'closeButton', 'body', 'footer'],
    baseStyle: {
        overlay: {
            bg: 'blackAlpha.500',
        },
        dialog: {
            w: 'fit-content',
            maxW: '721px',
            borderRadius: '20px',
            m: '17px',
        },
        header: {
            px: modalPaddingX,
            pr: '48px',
            pt: { base: '28px', md: '40px' },
            pb: { base: '12px', md: '16px' },
            fontSize: { base: '20px', md: '26px' },
            lineHeight: { base: '26px', md: '34px' },
            letterSpacing: '-0.01em',
        },
        closeButton: {
            top: 3,
        },
        body: {
            px: modalPaddingX,
            py: '0px',
            p: {
                fontSize: '16px',
                lineHeight: '24px',
            },
        },
        footer: {
            px: modalPaddingX,
            gridGap: '8px',
            pb: { base: '28px', md: '40px' },
            pt: { base: '24px', md: '28px' },
            flexDirection: { base: 'column-reverse', md: 'row' },
        },
    },
    variants: {},
    sizes: {
        lg: {
            dialog: {
                maxW: '721px',
            },
        },
        md: {
            dialog: {
                maxW: '494px',
            },
        },
        sm: {
            dialog: {
                maxW: '454px',
            },
        },
    },
}

import { ComponentStyleConfig } from '@chakra-ui/react'

// NB: According to the official docs (https://github.com/csandman/chakra-react-select#theme-styles)
//     the `Input` style config is pulled by the `(Async)Select` component of `chakra-react-select`.

// TODO [FEATURE-661]: Finalize theming for the Form Input components.
export const Input: ComponentStyleConfig = {
    baseStyle: ({ _colorScheme }: any) => {
        return {
            field: {
                _placeholder: { color: 'text.secondary' },
            },
        }
    },
    variants: {
        filled: ({ colorScheme, theme }: any) => {
            return {
                field: {
                    borderWidth: '1px',
                    borderRadius: '12px',
                    _invalid: {
                        // NB: Forced to use `!important` here to be able to override the `:focused` state.
                        borderColor: '#D21951 !important', // TODO [FEATURE-642]: Change to semantic when the Colors are ready.
                        boxShadow: `${theme.shadows.error} !important`,
                    },
                    // NB: The `:focus-visible` should be the only one used, but it doesn't work w/ Chakra.
                    _focus: {
                        borderColor: `${colorScheme}.500`,
                        boxShadow: `outline_${colorScheme}`,
                        outline: 'none',
                    },
                    _focusVisible: {
                        outline: 'none',
                    },
                    _disabled: {
                        cursor: 'not-allowed',
                    },
                    _readOnly: {
                        pointerEvents: 'none',
                    },
                },
            }
        },
        outline: ({ colorScheme, theme }: any) => {
            return {
                field: {
                    backgroundColor: 'white',
                    borderWidth: '1px',
                    borderRadius: '12px',
                    _invalid: {
                        // NB: Forced to use `!important` here to be able to override the `:focused` state.
                        borderColor: '#D21951 !important', // TODO [FEATURE-642]: Change to semantic when the Colors are ready.
                        boxShadow: `${theme.shadows.error} !important`,
                    },
                    // NB: The `:focus-visible` should be the only one used, but it doesn't work w/ Chakra.
                    _focus: {
                        borderColor: `${colorScheme}.500`,
                        boxShadow: `outline_${colorScheme}`,
                        outline: 'none',
                    },
                    _focusVisible: {
                        outline: 'none',
                    },
                    _disabled: {
                        cursor: 'not-allowed',
                    },
                    _readOnly: {
                        pointerEvents: 'none',
                    },
                },
            }
        },
    },
    defaultProps: {
        variant: 'filled',
        colorScheme: 'secondary',
    },
}

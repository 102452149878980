import { ComponentStyleConfig } from '@chakra-ui/react'

export const CardWrapper: ComponentStyleConfig = {
    baseStyle: {
        borderRadius: '16px',
        borderWidth: '1px',
        borderColor: 'gray.dividers',
        backgroundColor: 'white',
    },
    sizes: {
        // NB: Almost dropped in favor of the `theme.cardSizes` used on par
        //     with `auto-fit`/`auto-fill` CSS Grids, but kept just in case.
        sm: {
            maxWidth: '349px',
            padding: '24px',
        },
        full: {
            maxWidth: '100%',
            padding: '24px',
        },
    },
    defaultProps: {
        size: 'full',
    },
}

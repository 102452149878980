import { ComponentStyleConfig } from '@chakra-ui/react'

// TODO [FEATURE-642]: Change to semantic when the Colors are ready.
export const Notification: ComponentStyleConfig = {
    baseStyle: {
        fontWeight: 500,
        borderWidth: '1px',
        borderStyle: 'solid',
    },
    sizes: {
        md: {
            padding: '16px',
            fontSize: '13px',
            lineHeight: '20px',
        },
        lg: {
            padding: '20px 24px',
            fontSize: '16px',
            lineHeight: '24px',
        },
    },
    variants: {
        success: {
            color: '#32855D',
            backgroundColor: '#EAFBF3',
            borderColor: '#32855D',
        },
        error: {
            color: '#D21951',
            backgroundColor: '#FFE8EF',
            borderColor: '#D21951',
        },
        alert: {
            color: '#CB6113',
            backgroundColor: '#FFF6F0',
            borderColor: '#CB6113',
        },
        info: {
            color: '#4853C8',
            backgroundColor: '#EFF1FF',
            borderColor: '#4853C8',
        },
    },
    defaultProps: {
        variant: 'error',
        size: 'md',
    },
}

import { ComponentStyleConfig } from '@chakra-ui/react'

// TODO [FEATURE-620]: Apply the Styleguide > Visual Foundations > Typography.
// TODO [FEATURE-620]: Come up with better names for Text Styles (variants).
export const Text: ComponentStyleConfig = {
    baseStyle: ({ colorScheme }) => ({
        color: `${colorScheme}.500`,
        fontWeight: 500,
        lineHeight: 5,
    }),
    variants: {
        bodyTextSmall: {
            fontSize: '16px',
            lineHeight: '24px',
        },
        bodyTextMedium: {
            fontSize: '18px',
            lineHeight: '30px',
        },
        bodyTextLarge: {
            fontSize: '22px',
            lineHeight: '30px',
            letterSpacing: '-0.01em',
            fontWeight: 600,
        },
        functionalNormal: {
            fontSize: '13px',
            lineHeight: '18px',
        },
        functionalSemiBold: {
            fontSize: '13px',
            lineHeight: '20px',
            fontWeight: 600,
        },
        functionalButtonsSmall: {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 600,
        },
        functionalButtonsLarge: {
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 600,
        },
    },
    sizes: {
        sm: {
            fontSize: { base: '16px', lg: '13px' },
            lineHeight: '18px',
        },
    },
    defaultProps: {
        colorScheme: 'text',
        size: 'sm',
    },
}

import { ChakraProvider } from '@chakra-ui/react'
import type { AppProps } from 'next/app'
import { chakraTheme } from 'theme/theme'

export default function MyApp({ Component, pageProps }: AppProps) {
    return (
        <ChakraProvider theme={chakraTheme}>
            <Component {...pageProps} />
        </ChakraProvider>
    )
}

import { ComponentStyleConfig } from '@chakra-ui/react'

export const Avatar: ComponentStyleConfig = {
    baseStyle: {
        container: {
            backgroundColor: 'transparent',
        },
    },
    variants: {
        user: {
            container: {
                borderRadius: '50%',
            },
        },
        company: {
            container: {
                borderRadius: '6px',
            },
        },
    },
    defaultProps: {
        variant: 'user',
    },
}

export const breakpoints = {
    // $global-min-width =  320px
    // $global-max-width = 2000px

    // global
    xxs: '350px', // $xsmall-mobile
    xs: '370px', // $small-mobile
    sm: '480px', // $large-mobile
    md: '769px', // $tablet
    lg: '1088px', // $desktop
    xl: '1280px', // $widescreen

    // container-specific
    job_content: '994px',
    blog_content: '1014px',
    job_container: '1199px',
    jobsboard_container: '1202px',
    container1: '1128px',
    container2: '1440px',
}

import { ComponentStyleConfig } from '@chakra-ui/react'
import { StyleFunctionProps } from '@chakra-ui/theme-tools'
import { SVGHoverProps, getSVGHover } from 'theme/svgHover'

type ButtonStyleFunctionProps = StyleFunctionProps & SVGHoverProps

// TODO [FEATURE-671]: Finalize theming for the Button & Link components.
export const Button: ComponentStyleConfig = {
    baseStyle: (props: ButtonStyleFunctionProps) => {
        // TODO [FEATURE-675]: Make it a mixin so it preserves for variants `_hover`.
        const svgHoverStrategy = props['data-svg-hover-strategy']
        let svgHover = getSVGHover(svgHoverStrategy)

        return {
            textDecoration: 'none !important',
            fontWeight: 600,
            borderRadius: '6px',
            transition: 'background 0.3s, color 0.3s, border 0.3s',
            _disabled: {
                cursor: 'not-allowed',
            },
            _readOnly: {
                pointerEvents: 'none',
            },
            ...svgHover,
        }
    },
    sizes: {
        xs: {
            fontSize: '14px',
            lineHeight: '20px',
            height: '36px',
            padding: '8px 16px',
        },
        sm: {
            fontSize: '14px',
            lineHeight: '20px',
            height: '40px',
            padding: '10px 16px',
        },
        md: {
            fontSize: '16px',
            lineHeight: '20px',
            height: '48px',
            padding: '14px 20px',
        },
        lg: {
            fontSize: '16px',
            lineHeight: '20px',
            height: '56px',
            padding: '18px 24px',
        },
        modalFooter: {
            fontSize: '16px',
            lineHeight: '20px',
            height: '48px',
            padding: '14px 20px',
            w: { base: '100%', md: 'fit-content' },
        },
    },
    variants: {
        primary: ({ colorScheme }) => {
            const bgColor = `${colorScheme}.500`
            const bgColorHover = `${colorScheme}.600`
            return {
                color: 'white',
                backgroundColor: bgColor,
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: bgColor,
                _focus: {
                    boxShadow: `outline_${colorScheme}_lg`,
                    outline: 'none',
                },
                _focusVisible: {
                    outline: 'none',
                },
                _hover: {
                    color: 'white',
                    backgroundColor: bgColorHover,
                    borderColor: bgColorHover,
                    transition: 'background 0.3s, color 0.3s, border 0.3s',
                },
            }
        },
        secondary: ({ colorScheme }) => {
            const borderColor = `${colorScheme}.500`
            return {
                color: 'text.500',
                backgroundColor: 'white',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'gray.dividers',
                _focus: {
                    // borderColor: borderColor,
                    boxShadow: `outline_${colorScheme}_lg`,
                    outline: 'none',
                },
                _focusVisible: {
                    outline: 'none',
                },
                _hover: {
                    color: 'text.500',
                    borderColor: borderColor,
                    transition: 'background 0.3s, color 0.3s, border 0.3s',
                },
                _disabled: {
                    color: 'text.disabled',
                },
            }
        },
        // TODO [CU-2tye1h4]: Address the `borderOnly` Button variant. Rename! Should it be a separate component?
        borderOnly: ({ colorScheme }) => {
            return {
                color: 'text.500',
                backgroundColor: 'white',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: `${colorScheme}.500`,
                _focus: {
                    boxShadow: `outline_${colorScheme}_lg`,
                    outline: 'none',
                },
                _focusVisible: {
                    outline: 'none',
                },
                _hover: {
                    color: 'text.500',
                    backgroundColor: `${colorScheme}.400`,
                },
                _disabled: {
                    color: 'text.disabled',
                },
            }
        },
        tertiary: ({ _colorScheme }) => {
            // TODO [FEATURE-277]: Have to use 'colorScheme' as well.
            return {
                color: 'text.500',
                backgroundColor: 'gray.bg_light',
                _focus: {
                    boxShadow: `outline_gray_lg`,
                    outline: 'none',
                },
                _focusVisible: {
                    outline: 'none',
                },
                _hover: {
                    color: 'text.500',
                    backgroundColor: 'gray.dividers',
                },
                _disabled: {
                    color: 'text.disabled',
                },
            }
        },
        ghost: ({ colorScheme }) => {
            // TODO [FEATURE-277]: Here's an obvious inconsistency w/ Chakra's 'colorScheme'.
            //                     We are forced to use '600' to match our UI mockups.
            const color = `${colorScheme}.600`
            return {
                color: color,
                backgroundColor: 'none',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'transparent',
                _focus: {
                    boxShadow: `outline_${colorScheme}_lg`,
                    outline: 'none',
                },
                _focusVisible: {
                    outline: 'none',
                },
                _hover: {
                    color: color,
                    borderColor: color,
                },
                _disabled: {
                    color: 'text.disabled',
                },
            }
        },
    },
    defaultProps: {
        variant: 'primary',
        colorScheme: 'primary',
        size: 'sm',
    },
}

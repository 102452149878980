import { ComponentStyleConfig } from '@chakra-ui/react'
import { StyleFunctionProps } from '@chakra-ui/theme-tools'
import { SVGHoverProps, getSVGHover } from 'theme/svgHover'

type ExtraLinkProps = {
    // NB: We use such name format to avoid console log warnings for illegal
    //     props on a DOM element. This happens because of the Chakra `Link`
    //     component simply copying all props directly into an internal `a`.
    'data-is-active'?: boolean
    'data-is-mobile'?: boolean
}

type CustomLinkStyleFunctionProps = StyleFunctionProps & SVGHoverProps & ExtraLinkProps

// TODO [FEATURE-671]: Finalize theming for the Button & Link components.
export const Link: ComponentStyleConfig = {
    baseStyle: (props: CustomLinkStyleFunctionProps) => {
        // TODO [FEATURE-675]: Make it a mixin so it preserves for variants `_hover`.
        const svgHoverStrategy = props['data-svg-hover-strategy']
        let svgHover = getSVGHover(svgHoverStrategy)

        return {
            textDecoration: 'none !important',
            fontWeight: 500,
            transition: 'color 0.3s, border-bottom-color 0.3s',
            ...svgHover,
        }
    },
    sizes: {
        xs: {
            fontSize: 'xs',
        },
        sm: {
            fontSize: 'sm',
        },
    },
    variants: {
        header: (props: CustomLinkStyleFunctionProps) => {
            const isActive = props['data-is-active']
            const isMobile = props['data-is-mobile']

            return {
                color: 'text.500',
                borderTopWidth: isMobile ? 0 : '1.5px', // to compensate
                borderTopColor: 'transparent',
                borderBottomWidth: isMobile ? 0 : '1.5px',
                borderBottomColor: isActive ? 'primary.600' : 'transparent',
                borderBottomStyle: 'solid',
                transition: 'color 0.3s, border-bottom-color 0.3s',
                userSelect: 'none',
                cursor: isActive ? 'pointer' : undefined,
                _hover: {
                    borderBottomColor: 'primary.600',
                    textDecoration: 'none',
                },
                _focus: {
                    borderBottomColor: 'primary.600',
                    boxShadow: 'none',
                    outline: 'none',
                },
                _focusVisible: {
                    outline: 'none',
                },
            }
        },
        menuItem: (props: CustomLinkStyleFunctionProps) => {
            const isActive = props['data-is-active']
            const color = 'text.500'

            return {
                color: color,
                transition: 'color 0.3s, border-bottom-color 0.3s',
                cursor: isActive ? 'pointer' : undefined,
                pointerEvents: isActive ? 'none' : undefined,
                _hover: {
                    color: isActive ? color : 'primary.500',
                    borderBottomColor: 'primary.600',
                    textDecoration: 'none',
                },
                _focus: {
                    color: isActive ? color : 'primary.500',
                    borderBottomColor: 'primary.600',
                    boxShadow: 'none',
                    outline: 'none',
                },
                _focusVisible: {
                    outline: 'none',
                },
            }
        },
        // TODO [FEATURE-671]: This is too specific & not customizable, thus not a variant.
        hoverUnderline: {
            _hover: {
                textDecoration: 'underline !important',
                color: 'inherit',
            },
        },
        // TODO [FEATURE-671]: This is too specific & not customizable, thus not a variant.
        applicationCardDocument: () => {
            return {
                fontSize: '13px',
                lineHeight: '18px',
                borderRadius: '6px',
                padding: '5px 10px',
                borderWidth: '1px',
                borderColor: 'gray.dividers',
                cursor: 'pointer',
                _hover: {
                    color: 'secondary.600',
                    borderColor: 'secondary.500',
                },
                _focus: {
                    outline: 'none',
                    boxShadow: 'none',
                },
                _focusVisible: {
                    color: 'secondary.600',
                    borderColor: 'secondary.500',
                    boxShadow: 'none',
                    outline: 'none',
                },
            }
        },
    },
    defaultProps: {
        colorScheme: 'primary',
        size: 'sm',
        'data-svg-hover-strategy': 'stroke',
    },
}

import { ComponentStyleConfig } from '@chakra-ui/react'

export const HeadingSizes = {
    xl: {
        /* usually - desktop H1 */ fontSize: '50px',
        lineHeight: '58px',
        letterSpacing: '-0.02em',
    },
    lg: {
        /* usually - desktop H2 */ fontSize: '36px',
        lineHeight: '48px',
        letterSpacing: '-0.01em',
    },
    md: {
        /* usually - desktop H3, mobile H1 */ fontSize: '26px',
        lineHeight: '34px',
        letterSpacing: '-0.01em',
    },
    mobileH2: {
        /* usually - extra mobile H2 */ fontSize: '20px',
        lineHeight: '26px',
        letterSpacing: '-0.01em',
    },
    sm: {
        /* usually - desktop H4, mobile H3 */ fontSize: '18px',
        lineHeight: '24px',
        letterSpacing: '-0.01em',
    },
    xs: {
        /* usually - desktop H5, mobile H4 */ fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '-0.01em',
    },
    xxs: {
        /* usually - desktop H6, mobile H5 */ fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '-0.01em',
    },
    mobileH6: {
        /* usually - extra mobile H6 */ fontSize: '12px',
        lineHeight: '18px',
        letterSpacing: '-0.01em',
    },
} as const
export type HeadingSizeKey = keyof typeof HeadingSizes

export const Heading: ComponentStyleConfig = {
    baseStyle: ({ colorScheme }) => ({
        color: `${colorScheme}.500`,
        fontWeight: 600,
    }),
    // NB: This is left for the Chakra's `Heading` component.
    sizes: HeadingSizes,
    defaultProps: {
        colorScheme: 'text',
        // NB: Matches the default Chakra's `Heading` that is `h2`.
        size: 'lg',
    },
    variants: {
        blogPageTitle: {
            lineHeight: {
                base: HeadingSizes.md.lineHeight,
                md: HeadingSizes.xl.lineHeight,
            },
            fontSize: {
                base: HeadingSizes.md.fontSize,
                md: HeadingSizes.xl.fontSize,
            },
            letterSpacing: {
                base: HeadingSizes.md.letterSpacing,
                md: HeadingSizes.xl.letterSpacing,
            },
        },
        jobPageTitle: {
            lineHeight: {
                base: HeadingSizes.mobileH2.lineHeight,
                md: HeadingSizes.xl.lineHeight,
            },
            fontSize: {
                base: HeadingSizes.mobileH2.fontSize,
                md: HeadingSizes.xl.fontSize,
            },
            letterSpacing: {
                base: HeadingSizes.mobileH2.letterSpacing,
                md: HeadingSizes.xl.letterSpacing,
            },
        },
    },
}

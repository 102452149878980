import { borderState } from 'theme/components/RichTextEditor'

export const CompanyLogo = {
    baseStyle: ({ isInvalid, theme }: { isInvalid: boolean } & any) => {
        return {
            dropArea: {
                ...borderState(isInvalid, theme),
                borderRadius: theme.radii.md,
            },
        }
    },
}

import { ComponentStyleConfig } from '@chakra-ui/react'

export const Checkbox: ComponentStyleConfig = {
    sizes: {
        md: {
            control: {
                boxSize: '24px',
            },
            icon: {
                transform: 'scale(1.5, 1.2)', // 18x15
            },
            label: {
                ml: '12px',
                fontWeight: 500
            },
        },
    },
    defaultProps: {
        colorScheme: 'secondary',
        size: 'md',
    },
}

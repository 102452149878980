export type SvgHoverStrategy = 'none' | 'fill' | 'stroke' | 'both'

export type SVGHoverProps = {
    // NB: We use such name format to avoid console log warnings for illegal
    //     props on a DOM element. This happens because of the Chakra `Link`
    //     component simply copying all props directly into an internal `a`.
    'data-svg-hover-strategy'?: SvgHoverStrategy
}

export const getSVGHover = (svgHoverStrategy?: SvgHoverStrategy) => {
    if (svgHoverStrategy === 'none') {
        return
    }

    let svgHover: any = {
        transition: 'fill 0.3s, stroke 0.3s',
    }

    switch (svgHoverStrategy) {
        case 'fill':
        case 'stroke':
            svgHover['svg *'] = { [svgHoverStrategy]: 'currentColor' }
            break
        case 'both':
            svgHover['svg *'] = {
                stroke: 'currentColor',
                fill: 'currentColor',
            }
    }

    return svgHover
}

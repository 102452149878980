export const borderState = (isInvalid: boolean, theme: any) => {
    if (isInvalid) {
        return {
            borderColor: '#D21951', // TODO [FEATURE-642]: Change to semantic when the Colors are ready.
            boxShadow: theme.shadows.error,
        }
    } else {
        return {
            borderColor: theme.colors.gray[200], // TODO [FEATURE-642]: Use the proper gray tone.
            boxShadow: 'none',
        }
    }
}

export const RichTextEditor = {
    baseStyle: ({ isInvalid, theme }: { isInvalid: boolean } & any) => {
        return {
            toolbar: {
                borderTopLeftRadius: theme.radii.md,
                borderTopRightRadius: theme.radii.md,
                ...borderState(isInvalid, theme),
            },
            container: {
                borderBottomLeftRadius: theme.radii.md,
                borderBottomRightRadius: theme.radii.md,
                ...borderState(isInvalid, theme),
                //
                minHeight: '200px',
                //
                fontStyle: 'normal',
                fontSize: theme.fontSizes.md,
                fontFamily: theme.fonts.body,
            },
            editor: {
                paddingLeft: theme.space[4],
                paddingRight: theme.space[4],
            },
            placeholder: {
                fontStyle: 'inherit',
                color: theme.colors.gray[400], // TODO [FEATURE-642]: Use the proper gray tone.
            },
            textSizePicker: {
                fontFamily: theme.fonts.body,
            },
            textSizePickerOptions: {
                fontFamily: theme.fonts.body,
            },
        }
    },
}

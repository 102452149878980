import getConfig from 'next/config'

export function getEnv(envName: string) {
    const fullEnvName =
        typeof window === 'undefined' ? `${envName}Server` : `${envName}Client`
    return getConfig()?.publicRuntimeConfig[fullEnvName]
}

export function getEnvGlobal(envName: string) {
    return getConfig()?.publicRuntimeConfig[envName]
}

// prod, stage, ephemeral - values are provided by ReleaseHub
// dev - provided by npm scripts, package.json
export type Environment = 'prod' | 'stage' | 'ephemeral' | 'dev'
export function getEnvironment(): Environment {
    return getConfig()?.publicRuntimeConfig['environment']
}

export function getServerConfigVar(v: 'webIngressUrl' | 'redisUrl') {
    return getConfig()?.serverRuntimeConfig[v]
}

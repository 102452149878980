import Color from 'color'
import { merge } from 'ramda'

////////////////////////////////////////////////////////////////////////////////
// AUXILIARY FUNCTIONS & CONSTANTS
////////////////////////////////////////////////////////////////////////////////

// TODO [FEATURE-277]: Find a way to use Chakra's `colorScheme` with our extended color palettes.

const colorScaleStep = 0.2

function genColors(color: Color, step = colorScaleStep): Record<number, string> {
    return {
        100: color.lighten(step * 4).hex(),
        200: color.lighten(step * 3).hex(),
        300: color.lighten(step * 2).hex(),
        400: color.lighten(step).hex(),
        500: color.hex(),
        600: color.darken(step).hex(),
        700: color.darken(step * 2).hex(),
        800: color.darken(step * 3).hex(),
        900: color.darken(step * 4).hex(),
    }
}

function getOutline(color: string, size: 'sm' | 'md' | 'lg' = 'md') {
    switch (size) {
        case 'sm':
            return `0 0 0 1px ${Color(color).rgb()}`
        case 'md':
            return `0 0 4px 0 ${Color(color).alpha(0.4).rgb()}`
        case 'lg':
            return `0 0 4px 2px ${Color(color).alpha(0.4).rgb()},
                    0 0 2px 1px ${Color(color).alpha(0.6).rgb()}`
    }
}

////////////////////////////////////////////////////////////////////////////////
// BRAND COLORS
////////////////////////////////////////////////////////////////////////////////

// TODO [FEATURE-642]: Apply the Styleguide > Visual Foundations > Colors.

const PRIMARY_120 = Color('#c72828')
const PRIMARY_110 = Color('#e34f4f')
const PRIMARY_100 = Color('#ed6b6b')
const PRIMARY_55 = Color('#f9adad')
const PRIMARY_20 = Color('#fee8e8')
const PRIMARY_10 = Color('#fdf0f0')

const SECONDARY_DARK = Color('#303996')
const SECONDARY_TEXT = Color('#4853c8')
const SECONDARY_ACCENT = Color('#6c77ed')
const SECONDARY_DISABLED = Color('#b1b6f1')
const SECONDARY_LIGHT = Color('#e7e9fd')
const SECONDARY_XLIGHT = Color('#f2f3ff')

// TODO [FEATURE-277]: Tune up 'gray' usages in the 'appShell' and other places.
const GRAY_STROKE = Color('#cfcfd5')
const GRAY_BUTTONS = Color('#dddde2')
const GRAY_DIVIDERS = Color('#ececf1')
const GRAY_BACKGROUND = Color('#f6f6fb')
const GRAY_BACKGROUND_LIGHT = Color('#f8f8fc')
const GRAY_BACKGROUND_DARK = Color('#ECECF1')
const GRAY_GRAPHICS_DARK = Color('#C9C9D0')
const GRAY_DARK = Color('#BBBBC0')

const TEXT_BASIC = Color('#242424')
const TEXT_SECONDARY_DARK = Color('#6f6f74')
const TEXT_SECONDARY = Color('#828287')
const TEXT_SECONDARY_LIGHT = Color('#9e9ea7') // + hover for text links?
const TEXT_DISABLED = Color('#b5b5c0')
const TEXT_DISABLED_LIGHT = Color('#d3d3da')

const SEMANTIC_SUCCESS_ACCENT = Color('#0b8f50')
const SEMANTIC_SUCCESS_ACCENT_LIGHT = Color('#41cc89')
const SEMANTIC_SUCCESS_BG = Color('#daf1e6')
const SEMANTIC_SUCCESS_BG_LIGHT = Color('#eafbf3')

const SEMANTIC_ERROR_ACCENT = Color('#d21951')
const SEMANTIC_ERROR_ACCENT_LIGHT = Color('#dd567f')
const SEMANTIC_ERROR_BG = Color('#ffd8e4')
const SEMANTIC_ERROR_BG_LIGHT = Color('#ffe8ef')

const SEMANTIC_ALERT_ACCENT = Color('#cb6113')
const SEMANTIC_ALERT_ACCENT_LIGHT = Color('#e88b47')
const SEMANTIC_ALERT_BG = Color('#F5DFD0')
const SEMANTIC_ALERT_BG_LIGHT = Color('#FFF0E5')

// necessary legacy FE app colors
export const LEGACY_APP_BACKGROUND_COLOR = '#EAECF2'

const primary_wh = {
    100: '#C72828',
    200: '#E34F4F',
    300: '#ED6B6B',
    400: '#F9ADAD',
    500: '#FEE8E8',
    600: '#FDF0F0',
}

const secondary_wh = {
    100: '#303996',
    200: '#4853C8',
    300: '#6C77ED',
    400: '#B1B6F1',
    500: '#E7E9FD',
    600: '#F2F3FF',
}

const red_wh = {
    100: '#AB204A',
    200: '#C43963',
    300: '#DD567F',
    400: '#FF86AA',
    500: '#FFD8E4',
    600: '#FFE8EF',
}

const green_wh = {
    100: '#15613C',
    200: '#2C8A5D',
    300: '#41CC89',
    400: '#9DE0BF',
    500: '#DAF1E6',
    600: '#EAFBF3',
}

const yellow_wh = {
    100: '#A24E0F',
    200: '#CB6113',
    300: '#FF9330',
    400: '#FFA959',
    500: '#FFE3CF',
    600: '#FFF6F0',
}

export const colors = {
    // TODO [FEATURE-277]: Verify the idea of "scale" for 'primary', 'secondary', and 'gray' w/ Anastasia.
    primary: {
        100: PRIMARY_10.lighten(colorScaleStep).hex(),
        200: PRIMARY_10.hex(),
        300: PRIMARY_20.hex(),
        400: PRIMARY_55.hex(),
        500: PRIMARY_100.hex(),
        600: PRIMARY_110.hex(),
        700: PRIMARY_120.hex(),
        800: PRIMARY_120.darken(colorScaleStep).hex(),
        900: PRIMARY_120.darken(colorScaleStep * 2).hex(),
    },
    secondary: {
        100: SECONDARY_XLIGHT.hex(),
        200: SECONDARY_LIGHT.hex(),
        300: SECONDARY_DISABLED.hex(),
        400: SECONDARY_ACCENT.lighten(colorScaleStep).hex(),
        500: SECONDARY_ACCENT.hex(),
        600: SECONDARY_TEXT.hex(),
        700: SECONDARY_DARK.hex(),
        800: SECONDARY_DARK.darken(colorScaleStep).hex(),
        900: SECONDARY_DARK.darken(colorScaleStep * 2).hex(),

        dark: SECONDARY_DARK.hex(),
        text: SECONDARY_TEXT.hex(),
        accent: SECONDARY_ACCENT.hex(),
        disabled: SECONDARY_DISABLED.hex(),
        light: SECONDARY_LIGHT.hex(),
        xlight: SECONDARY_XLIGHT.hex(),
    },
    gray: {
        stroke: GRAY_STROKE.hex(),
        buttons: GRAY_BUTTONS.hex(),
        dividers: GRAY_DIVIDERS.hex(),
        background: GRAY_BACKGROUND.hex(),
        bg_light: GRAY_BACKGROUND_LIGHT.hex(),
        bg_dark: GRAY_BACKGROUND_DARK.hex(),
        graphics_dark: GRAY_GRAPHICS_DARK.hex(),
        dark: GRAY_DARK.hex(),
    },
    text: merge(genColors(TEXT_BASIC), {
        secondary_dark: TEXT_SECONDARY_DARK.hex(),
        secondary: TEXT_SECONDARY.hex(),
        secondary_light: TEXT_SECONDARY_LIGHT.hex(),
        disabled: TEXT_DISABLED.hex(),
        disabled_light: TEXT_DISABLED_LIGHT.hex(),
    }),
    semantic: {
        success: {
            accent: SEMANTIC_SUCCESS_ACCENT.hex(),
            accent_light: SEMANTIC_SUCCESS_ACCENT_LIGHT.hex(),
            bg: SEMANTIC_SUCCESS_BG.hex(),
            bg_light: SEMANTIC_SUCCESS_BG_LIGHT.hex(),
        },
        error: {
            accent: SEMANTIC_ERROR_ACCENT.hex(),
            accent_light: SEMANTIC_ERROR_ACCENT_LIGHT.hex(),
            bg: SEMANTIC_ERROR_BG.hex(),
            bg_light: SEMANTIC_ERROR_BG_LIGHT.hex(),
        },
        alert: {
            accent: SEMANTIC_ALERT_ACCENT.hex(),
            accent_light: SEMANTIC_ALERT_ACCENT_LIGHT.hex(),
            bg: SEMANTIC_ALERT_BG.hex(),
            bg_light: SEMANTIC_ALERT_BG_LIGHT.hex(),
        },
    },
    primary_wh,
    secondary_wh,
    red_wh,
    green_wh,
    yellow_wh,
}

// TODO [FEATURE-642]: Add outline versions for all colors (incl. semantic ones) & sizes.
export const shadows = {
    menu: '2px 4px 22px rgba(112, 115, 139, 0.18)',
    dropdown: '1px 1px 8px rgba(112, 115, 139, 0.12)',
    outline_primary: getOutline(colors.primary[500]),
    outline_primary_lg: getOutline(colors.primary[500], 'lg'),
    outline_secondary: getOutline(colors.secondary[500]),
    outline_secondary_lg: getOutline(colors.secondary[500], 'lg'),
    outline_gray_sm: getOutline(colors.gray.dividers, 'sm'),
    outline_gray: getOutline(colors.gray.buttons),
    outline_gray_lg: getOutline(colors.gray.buttons, 'lg'),
    error: `0 0 4px rgba(227, 14, 91, 0.25)`,
    cookieConsentBanner: '0 0 15px rgb(0 0 0 / 15%)',
}

export const gradients = {
    slider_track:
      'linear-gradient(270deg, rgba(97, 110, 242, 0.21) 2.7% , ' +
      'rgba(97, 110, 242, 0.042) 95.27%)',
    message_background:
        'linear-gradient(108.59deg, rgba(255, 159, 222, 0.0648) 4.98%, ' +
        'rgba(252, 150, 203, 0.0795532) 29.81%, rgba(244, 126, 149, 0.0853897) 98.95%), ' +
        'linear-gradient(0deg, rgba(255, 118, 159, 0.17), rgba(255, 118, 159, 0.17))',
    match_flair_background:
        'linear-gradient(137.79deg, #959DFD -15.41%, ' +
        'rgba(150, 157, 252, 0.993217) -9.61%, rgba(151, 157, 250, 0.983187) -4.83%, ' +
        'rgba(154, 156, 248, 0.969565) -0.76%, rgba(157, 156, 246, 0.952004) 2.9%, ' +
        'rgba(161, 155, 243, 0.930159) 6.46%, rgba(165, 154, 239, 0.903684) 10.22%, ' +
        'rgba(171, 153, 234, 0.872232) 14.49%, rgba(177, 152, 228, 0.83546) 19.58%, ' +
        'rgba(185, 150, 222, 0.79302) 25.79%, rgba(193, 148, 215, 0.744567) 33.42%, ' +
        'rgba(203, 146, 207, 0.689754) 42.79%, rgba(213, 144, 198, 0.628237) 54.2%, ' +
        'rgba(225, 142, 188, 0.55967) 67.96%, rgba(238, 139, 176, 0.483706) 84.37%, ' +
        'rgba(253, 136, 164, 0.4) 103.74%)',
}

import { ComponentStyleConfig } from '@chakra-ui/react'

export const Flair: ComponentStyleConfig = {
    baseStyle: {
        display: 'flex',
        gap: '2px',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'fit-content',
        borderRadius: '6px',
        borderWidth: '0',
        fontWeight: 600,
    },
    sizes: {
        sm: {
            padding: '2px 8px',
            maxHeight: '20px',
            fontSize: '11px',
            lineHeight: '14px',
        },
        md: {
            padding: '5px 10px',
            maxHeight: '24px',
            fontSize: '13px',
            lineHeight: '18px',
        },
    },
    variants: {
        alert: ({ theme }) => {
            return {
                backgroundColor: theme.colors.semantic.alert.bg_light,
                color: theme.colors.semantic.alert.accent,
            }
        },
    },
    defaultProps: {
        size: 'md',
    },
}
